import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Switch } from 'antd';

export const SettingsForm = ({
  company,
  loading,
  onCancel,
  onSubmit,
  visible,
}) => {
  const [payroll, setPayroll] = useState(false);

  useEffect(() => {
    if (visible && company?.configuration) {
      if (company.configuration.show_no_employee) {
        setPayroll(true);
      } else {
        setPayroll(false);
      }
    } else {
      setPayroll(false);
    }
  }, [visible, company]);

  const handleSubmit = () => {
    let oSend = {
      show_no_employee: payroll,
    };
    onSubmit(oSend);
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={handleSubmit}
      title="Configuraciones"
      visible={visible}
    >
      <Form layout="horizontal">
        <Form.Item label="Habilitar Nóminas">
          <Switch onChange={checked => setPayroll(checked)} checked={payroll} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

SettingsForm.propTypes = {
  company: PropTypes.object,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
};
